import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121')
];

export const server_loads = [0];

export const dictionary = {
		"/(app)": [18,[2],[3]],
		"/_component/[...componentName]": [118],
		"/(app)/announcements": [21,[2,5],[3]],
		"/(app)/announcements/[id]/edit": [22,[2,5],[3]],
		"/(app)/backrooms": [23,[2],[3]],
		"/(app)/birthdays": [24,[2],[3]],
		"/(app)/bookings": [25,[2],[3]],
		"/(app)/bookings/[code]": [26,[2],[3]],
		"/(app)/changelog": [27,[2],[3]],
		"/(app)/claim-code": [28,[2],[3]],
		"/(app)/claim-code/[code]": [29,[2],[3]],
		"/(external)/connect/google/callback": [116,[16]],
		"/(app)/credits": [30,[2],[3]],
		"/(app)/delete-account": [31,[2],[3]],
		"/(app)/documents": [32,[2,6],[3]],
		"/(app)/documents/create": [39,[2],[3]],
		"/(app)/documents/[major]": [33,[2,6],[3]],
		"/(app)/documents/[major]/[yearTier=display_year_tier]": [34,[2,6],[3]],
		"/(app)/documents/[major]/[yearTier=display_year_tier]/[subject]": [35,[2,6],[3]],
		"/(app)/documents/[major]/[yearTier=display_year_tier]/[subject]/create": [38,[2,6],[3]],
		"/(app)/documents/[major]/[yearTier=display_year_tier]/[subject]/[document]": [36,[2,6],[3]],
		"/(app)/documents/[major]/[yearTier=display_year_tier]/[subject]/[document]/edit": [37,[2,6],[3]],
		"/(app)/events/[[week=date]]": [40,[2],[3]],
		"/(app)/events/[id]": [41,[2,7],[3]],
		"/(app)/events/[id]/bookings": [42,[2,7],[3]],
		"/(app)/events/[id]/edit": [43,[2,7,8],[3]],
		"/(app)/events/[id]/edit/banned": [44,[2,7,8],[3]],
		"/(app)/events/[id]/edit/contact": [45,[2,7,8],[3]],
		"/(app)/events/[id]/edit/description": [46,[2,7,8],[3]],
		"/(app)/events/[id]/edit/image": [47,[2,7,8],[3]],
		"/(app)/events/[id]/edit/links": [48,[2,7,8],[3]],
		"/(app)/events/[id]/edit/managers": [49,[2,7,8],[3]],
		"/(app)/events/[id]/edit/recurrence": [50,[2,7,8],[3]],
		"/(app)/events/[id]/edit/ticket-groups/[group]": [51,[2,7,8],[3]],
		"/(app)/events/[id]/edit/tickets": [52,[2,7,8],[3]],
		"/(app)/events/[id]/edit/tickets/[ticket]": [53,[2,7,8],[3]],
		"/(app)/events/[id]/edit/tickets/[ticket]/counting": [54,[2,7,8],[3]],
		"/(app)/events/[id]/edit/tickets/[ticket]/group": [55,[2,7,8],[3]],
		"/(app)/events/[id]/edit/tickets/[ticket]/invited": [56,[2,7,8],[3]],
		"/(app)/events/[id]/edit/tickets/[ticket]/links": [57,[2,7,8],[3]],
		"/(app)/events/[id]/edit/tickets/[ticket]/payment": [58,[2,7,8],[3]],
		"/(app)/events/[id]/edit/visibility": [59,[2,7,8],[3]],
		"/(app)/events/[id]/join/[code]": [60,[2,7],[3]],
		"/(app)/events/[id]/scan": [61,[2,7],[3]],
		"/(app)/groups/[uid]/edit": [62,[2,9,10],[3]],
		"/(app)/groups/[uid]/edit/bank-accounts": [63,[2,9,10],[3]],
		"/(app)/groups/[uid]/edit/bio": [64,[2,9,10],[3]],
		"/(app)/groups/[uid]/edit/links": [65,[2,9,10],[3]],
		"/(app)/groups/[uid]/edit/members/bulk": [66,[2,9,10],[3]],
		"/(app)/groups/[uid]/edit/pages": [67,[2,9,10],[3]],
		"/(app)/groups/[uid]/edit/pages/[...page]": [68,[2,9,10],[3]],
		"/(app)/groups/[uid]/edit/type": [69,[2,9,10],[3]],
		"/(app)/groups/[uid]/members": [70,[2,9],[3]],
		"/(app)/help": [71,[2],[3]],
		"/(app)/join-managers/[code]": [72,[2],[3]],
		"/(external)/kiosk": [117,[16]],
		"/(app)/login": [73,[2],[3]],
		"/(app)/login/done": [74,[2],[3]],
		"/(app)/login/forgotten": [75,[2],[3]],
		"/(app)/login/reset/[token]": [76,[2],[3]],
		"/(app)/logout": [~77,[2],[3]],
		"/(app)/logs": [78,[2],[3]],
		"/(app)/more": [79,[2],[3]],
		"/(app)/notifications": [80,[2],[3]],
		"/(app)/posts/[id]": [81,[2],[3]],
		"/(app)/posts/[id]/edit": [82,[2,11],[3]],
		"/(app)/posts/[id]/edit/body": [83,[2,11],[3]],
		"/(app)/posts/[id]/edit/event": [84,[2,11],[3]],
		"/(app)/posts/[id]/edit/links": [85,[2,11],[3]],
		"/(app)/posts/[id]/edit/picture": [86,[2,11],[3]],
		"/(app)/posts/[id]/edit/visibility": [87,[2,11],[3]],
		"/(app)/quick-signups/create": [88,[2],[3]],
		"/(app)/quick-signups/manage": [89,[2],[3]],
		"/(app)/quick-signups/qr/[code]": [~90,[12]],
		"/(app)/search/[[q]]": [91,[2],[3]],
		"/(app)/services": [92,[2],[3]],
		"/(app)/services/manage": [94,[2],[3]],
		"/(app)/services/submit": [95,[2],[3]],
		"/(app)/services/[id]/edit": [93,[2],[3]],
		"/(app)/set-password": [96,[2],[3]],
		"/(app)/settings": [97,[2,13],[3]],
		"/(app)/settings/theme": [98,[2,13],[3]],
		"/(app)/signups": [99,[2,14],[3]],
		"/(app)/signups/edit/[email]": [100,[2,14],[3]],
		"/signup": [119,[17]],
		"/signup/finish/[token]": [~121,[17]],
		"/signup/[qrcode]": [120,[17]],
		"/(app)/student-associations/[uid]/edit/pages": [102,[2],[3]],
		"/(app)/student-associations/[uid]/edit/pages/[...page]": [103,[2],[3]],
		"/(app)/student-associations/[uid]/[...page]": [101,[2],[3]],
		"/(app)/users/[uid]/edit": [104,[2,15],[3]],
		"/(app)/users/[uid]/edit/bio": [105,[2,15],[3]],
		"/(app)/users/[uid]/edit/contributions": [106,[2,15],[3]],
		"/(app)/users/[uid]/edit/curriculum": [107,[2,15],[3]],
		"/(app)/users/[uid]/edit/email": [108,[2,15],[3]],
		"/(app)/users/[uid]/edit/family": [109,[2,15],[3]],
		"/(app)/users/[uid]/edit/links": [110,[2,15],[3]],
		"/(app)/users/[uid]/edit/name": [111,[2,15],[3]],
		"/(app)/users/[uid]/edit/other-emails": [112,[2,15],[3]],
		"/(app)/users/[uid]/edit/permissions": [113,[2,15],[3]],
		"/(app)/validate-email/[token]": [114,[2],[3]],
		"/(app)/welcome": [115,[2],[3]],
		"/(app)/[uid=uid]": [19,[2,4],[3]],
		"/(app)/[uid=uid]/[...page]": [20,[2],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';